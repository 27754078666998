<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template>
      <b-breadcrumb :items="breadCrumbs"></b-breadcrumb>
    </template>

    <v-banner single-line dark>
      <v-row>
        <v-col cols="2">
          <BackActionBar @goback="goback"></BackActionBar>
        </v-col>
        <v-col cols="8">
          <ContextualActionBar
            title="Schede Operative e TAD"
          ></ContextualActionBar>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row justify-end align-center">
        <v-col cols="2">
          <v-select
            :items="sortBy"
            item-text="label"
            item-value="value"
            v-model="selectedSort"
            outlined
            label="Ordine di visualizzazione"
            dense
            hide-details
            class="pt-2"
            v-on:change="getCards"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            :items="serviceFilters"
            item-text="label"
            item-value="value"
            v-model="selectedService"
            outlined
            label="Servizio"
            dense
            hide-details
            class="pt-2"
            v-on:change="getCards"
          ></v-select>
        </v-col>

        <v-col cols="2">
          <v-select
            :items="ddAreaFilters"
            item-text="label"
            item-value="value"
            v-model="selectedAreaDd"
            outlined
            label="Area D&D dettaglio"
            dense
            hide-details
            multiple
            class="pt-2"
            v-on:change="getCards"
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-switch
            label="SOLO PROD"
            v-model="envSwitch"
            color="secondary"
            value
            input-value="true"
            v-on:change="switchAction"
          ></v-switch>
        </v-col>
        <v-col cols="2" class="d-none">
          <v-select
            :items="classFilters"
            item-text="label"
            item-value="value"
            v-model="selectedClass"
            outlined
            label="Classe"
            dense
            hide-details
            class="pt-2"
            v-on:change="getCards"
          ></v-select>
        </v-col>
        <v-col cols="2" class="d-none">
          <v-select
            :items="rtoFilters"
            item-text="label"
            item-value="value"
            v-model="selectedRto"
            outlined
            label="RTO"
            dense
            hide-details
            class="pt-2"
            v-on:change="getCards"
          ></v-select>
        </v-col>

        <v-col cols="2" class="ml-auto">
          <v-text-field
            v-model="search"
            label="Cerca codice / nome servizio"
            placeholder="Cerca codice / nome servizio"
            filled
            rounded
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            clear-icon="mdi-close-circle"
            clearable
            class="pt-2 pr-3 pl-0"
            @change="updateItemsSearch"
            :class="dynamicClass"
            @focus="enlargeInputSearch"
            @blur="enlargeInputSearch"
          ></v-text-field>
        </v-col>
        <v-col cols="12 legendaMargin">
          <span class="legendText bold"> Legenda:</span>&ensp;
          <span class="legendText">
            <v-icon class="legendIconBS">mdi-square-rounded</v-icon>
            Business Service</span
          >&ensp;

          <span class="legendText">
            <v-icon class="legendIconBSNP">mdi-square-rounded</v-icon>Business
            Service NOPROD o Fuori Perimetro</span
          >&ensp;

          <span class="legendText">
            <v-icon class="legendIconIS">mdi-square-rounded</v-icon
            >Infrastructure Service</span
          >&ensp;

          <span class="legendText"
            ><v-icon class="legendIconISNP">mdi-square-rounded</v-icon>
            Infrastructure Service NOPROD o Fuori Perimetro</span
          >
        </v-col>
      </v-row>
    </v-banner>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto pt-0"
      max-height="calc(100vh - 180px)"
      color="accent"
    >
      <perfect-scrollbar>
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(item, index) in items"
              :key="index"
              cols="12"
              xl="4"
              lg="6"
              md="12"
              sm="12"
            >
              <OperatingCard
                :infoCard="item"
                :userProfile="userProfile"
                @open-dialog-info="getInfoService"
              ></OperatingCard>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <template>
        <v-footer
          padless
          class="my-2"
          align="center"
          justify="center"
          color="accent"
        >
          <span class="white--text ml-4">Schede per pagina</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark text color="secondary" v-bind="attrs" v-on="on">
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title v-if="number < itemsTotal">
                  {{ number }}
                </v-list-item-title>
                <v-list-item-title v-else> Tutte </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span class="white--text ml-4">Totale schede:</span
          ><span class="secondary--text ml-1"> {{ itemsTotal }}</span>

          <v-spacer></v-spacer>

          <span class="grey--text mr-4">
            Pagina {{ page }} di {{ numberOfPages }}
          </span>
          <v-btn fab x-small color="secondary" class="mr-1" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            color="secondary"
            class="ml-1 mr-4"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-footer>
      </template>
    </v-sheet>
    <ModalInfo
      @open-dialog-info="updateDialogInfo"
      :dialogInfo="openDialogInfo"
      :info="infoItems"
    ></ModalInfo>
  </v-card>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import OperatingCard from "../components/core/OperatingCard.vue";
import ModalInfo from "@/components/ModalInfo.vue";
import BackActionBar from "../components/core/BackActionBar.vue";

export default {
  name: "OperatingCards",
  components: { ContextualActionBar, OperatingCard, ModalInfo, BackActionBar },
  mixins: [connectionMixin],
  data() {
    return {
      displayProgress: true,
      sortBy: [
        { value: "az", label: "NOME SERVIZIO [A-Z]" },
        { value: "came", label: "codice CAME" },
      ],
      selectedSort: "az",
      serviceFilters: [
        { value: "all", label: "Tutte" },
        { value: "business_service", label: "Business service" },
        { value: "infrastructure_service", label: "Infrastructure service" },
      ],
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Schede Operative",
          href: "#",
        },
      ],
      selectedService: "all",
      ddFilters: [],
      ddAreaFilters: [],
      envFilters: [],
      envFilter: [{ value: "PROD", label: "PROD" }],
      selectedDd: "all",
      selectedAreaDd: [],
      selectedEnv: "PROD",
      envSwitch: true,
      classFilters: [
        { value: "all", label: "Tutte" },
        { value: "c1", label: "C1" },
        { value: "c2", label: "C2" },
        { value: "c3", label: "C3" },
      ],
      selectedClass: "all",
      rtoFilters: [
        { value: "4settimane", label: "4 settimane" },
        { value: "1settimana", label: "1 settimana" },
        { value: "48ore", label: "48 ore" },
        { value: "24ore", label: "24 ore" },
        { value: "8ore", label: "8 ore" },
        { value: "4ore", label: "4 ore" },
        { value: "2ore", label: "2 ore" },
        { value: "30minuti", label: "30 minuti" },
        { value: "tendenteZero", label: "Tendente a 0" },
        { value: "nessunTarget", label: "Nessun Target" },
        { value: "stringaVuota", label: "Stringa vuota" },
        { value: "all", label: "Tutte" },
      ],
      selectedRto: "all",
      itemsPerPageArray: [12, 24, 48],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 12,
      startPage: 0,
      items: [],
      itemsTotal: 0,
      openDialogInfo: false,
      infoItems: {},
      dynamicClass: "searchInactive",
      userProfile: "",
    };
  },
  mounted() {
    this.getUserProfile();
    this.getCards();
    this.getCardsDandD();
    //this.getCardsEnv();
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.itemsTotal / this.itemsPerPage);
    },
  },
  methods: {
    enlargeInputSearch() {
      this.dynamicClass =
        this.dynamicClass == "searchInactive"
          ? "searchActive"
          : "searchInactive";
      return this.dynamicClass;
    },
    updateDialogInfo(value) {
      this.openDialogInfo = value;
    },
    async getInfoService(value, id) {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            id +
            this.$env.value("API_INFO_SERVICE"),
          this.opts
        );
        this.infoItems = response.data;

        this.updateDialogInfo(value);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    switchAction() {
      if (this.envSwitch == true) {
        this.selectedEnv = "PROD";
        this.getCards();
      } else {
        this.selectedEnv = "all";
        this.getCards();
      }
    },
    async getCards() {
      if (this.selectedAreaDd.length > 0) {
        this.selectedAreaDd.forEach((item) => {
          if (item == "all") {
            this.selectedAreaDd = [];
          }
        });
      }
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") + this.$env.value("API_CARDS"),
          {
            filter: this.selectedService,
            sort: this.selectedSort,
            search: this.search,
            dd: this.selectedDd,
            ddAreaValues: this.selectedAreaDd,
            serviceClass: this.selectedClass,
            rto: this.selectedRto,
            start: this.page - 1,
            offset: this.itemsPerPage,
            env: this.selectedEnv,
          },
          this.opts
        );
        this.itemsTotal = response.data.total;
        if (this.itemsPerPageArray.length > 3)
          this.itemsPerPageArray.splice(3, 1);
        this.itemsPerPageArray.push(this.itemsTotal);
        this.items = response.data.cards;

        this.displayProgress = false;
      } catch (error) {
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
        console.log(error);
      }
    },
    async getCardsDandD() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            this.$env.value("API_AVAILABLE_AREA_DD"),
          this.opts
        );
        this.ddAreaFilters = response.data;
        this.ddAreaFilters.unshift({ value: "all", label: "Tutte" });
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    async getCardsEnv() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            this.$env.value("API_AVAILABLE_ENV"),
          this.opts
        );
        this.envFilters = response.data;
        this.envFilters.unshift({ value: "all", label: "Tutte" });
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        this.userProfile = response.data;

        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    updateItemsSearch() {
      this.page = 1;
      this.search = this.search == null ? "" : this.search;
      this.getCards();
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.startPage += this.itemsPerPage;
        this.page += 1;
        this.getCards();
      }
    },
    prevPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.startPage -= this.itemsPerPage;
        this.getCards();
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.startPage = 0;
      this.getCards();
    },
    goback() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.ps {
  height: calc(100vh - 350px);
}

.searchInactive {
  width: auto;
  transition: all 0.5s;
}
.searchActive {
  position: absolute;
  width: 33%;
  right: 0;
  left: 66%;
  top: 58%;
  background-color: #626262;
  z-index: 10;
  padding: 4px !important;
}
.legendIconBS {
  color: #2196f3;
  font-size: 15px;
}
.legendIconBSNP {
  font-size: 15px;
  background: #2196f3;
  background: linear-gradient(to right, #2196f3 0%, #000000 80%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.legendIconIS {
  color: #007c80;
  font-size: 15px;
}
.legendIconISNP {
  font-size: 15px;
  background: #007c80;
  background: linear-gradient(to right, #007c80 0%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.legendText {
  color: white;
  font-size: 10px;
}
.bold {
  font-weight: bold;
}
.legendaMargin {
  margin-top: -20px;
}
</style>

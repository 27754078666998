<template>
  <v-card>
    <v-card-subtitle
      class="white--text text-overline pa-2 d-flex justify-space-between"
      :class="classStyle"
    >
      <div>{{ infoCard.came ? infoCard.came : "N/A" }}</div>
      <div>{{ infoCard.dandDArea ? infoCard.dandDArea : "N/A" }}</div>
    </v-card-subtitle>
    <v-card-title>
      <h6 class="text-truncate">{{ infoCard.name ? infoCard.name : "N/A" }}</h6>
    </v-card-title>

    <v-card-text class="fontSize">
      <div class="d-flex justify-space-between">
        <div id="referente">
          <span class="font-weight-bold">REFERENTE</span>
          &emsp;{{ infoCard.referente ? infoCard.referente : "N/A" }}
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div>
          <span class="font-weight-bold">STATO CATALOGO</span>
          &emsp;{{ infoCard.state ? infoCard.state : "N/A" }}
        </div>
        <div>
          <span class="font-weight-bold"> STATO CMDB</span>
          &emsp;{{
            infoCard.lifecycleStatus ? infoCard.lifecycleStatus : "N/A"
          }}
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div>
          <span class="font-weight-bold">DISPONIBILITÀ </span>
          {{ infoCard.disponibilita ? infoCard.disponibilita : "N/A" }}
        </div>
        <div>
          <span class="font-weight-bold">CLASSE </span>
          {{ infoCard.serviceClass ? infoCard.serviceClass : "N/A" }}
        </div>
        <div>
          <span class="font-weight-bold">RTO </span>
          {{ infoCard.rto ? infoCard.rto : "N/A" }}
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div>
          <span class="font-weight-bold">CONFORMITÀ CLASSE DI SERVIZIO</span>
          &nbsp;
          <img
            class="imgicon"
            v-if="infoCard.checkCompliance == 'CONFORME CON NOTE'"
            :src="require('../../assets/conforme-note.png')"
          />
          <img
            class="imgicon"
            v-if="infoCard.checkCompliance == 'CONFORME'"
            :src="require('../../assets/conforme.png')"
          />
          <img
            class="imgicon"
            v-if="infoCard.checkCompliance == 'NON CONFORME'"
            :src="require('../../assets/non-conforme.png')"
          />
          <img
            class="imgicon"
            v-if="infoCard.checkCompliance == 'FUORI PERIMETRO'"
            :src="require('../../assets/fuori-perimetro.png')"
          />
          <img
            class="imgicon"
            v-if="infoCard.checkCompliance == ''"
            :src="require('../../assets/fuori-perimetro.png')"
          />

          <span>
            {{
              infoCard.checkCompliance
                ? infoCard.checkCompliance
                : infoCard.environment == "PROD"
                ? "N/A"
                : "N/A - NOPROD"
            }}</span
          >
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <!--   <v-tooltip bottom>
        getIcon(infoCard.checkCompliance)
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded icon v-bind="attrs" v-on="on" @click="goToDetail()">
            <v-icon color="primary">mdi-card-text-outline</v-icon>
          </v-btn>
        </template>
        <span>apri Scheda Operativa</span>
      </v-tooltip> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click="goToDetailNewTab()"
          >
            <v-icon color="primary">mdi-card-text-outline</v-icon>
          </v-btn>
        </template>
        <span>Apri Scheda Operativa in Nuova Scheda</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop="openDialogInfo(true, infoCard.id)"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>Leggi maggiori informazioni</span>
      </v-tooltip>

      <v-tooltip bottom v-if="userProfile !== 'readonly'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop="goToEdit(infoCard.id)"
          >
            <v-icon color="primary">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Modifica Scheda</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop="goTAD(true, infoCard.id)"
          >
            <img
              :src="require('../../assets/tad-favicon-32x32.png')"
              style="width: 24px"
            />
          </v-btn>
        </template>
        <span>TAD</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded icon v-bind="attrs" v-on="on" @click="goToCerts()">
            <v-icon :color="calcColor">mdi-certificate</v-icon>
          </v-btn>
        </template>
        <span> {{ calcCertStatus }} </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop="returnSLRLink(infoCard.came)"
          >
            <img
              :src="require('../../assets/slr-favicon-32x32.png')"
              style="width: 24px"
            />
          </v-btn>
        </template>
        <span>SLR</span>
      </v-tooltip>
      <v-spacer class="nomargin"></v-spacer>
    </v-card-actions>
    <ModalCertificati
      @close-dialog="certsDialogOpen = false"
      :dialogOpen="certsDialogOpen"
    ></ModalCertificati>
  </v-card>
</template>

<script>
import userMixin from "@/mixins/AppBarMenuUser.js";
import ModalCertificati from "@/components/ModalCertificati.vue";
export default {
  components: {
    ModalCertificati,
  },
  props: {
    // type: String,
    // label: String,
    // namecard: String,
    // id: String,
    // came: Number,
    infoCard: Object,
    userProfile: String,
  },
  mixins: [userMixin],
  data() {
    return {
      itemsMenu: [{ title: "Scheda Operativa" }],
      certsDialogOpen: false,
    };
  },
  mounted() {},
  computed: {
    calcColor() {
      // primary: "#333",
      // accent: "#444",
      // grigio - nessun certificato presente

      // success: "#4CAF50",
      // verde - stato dei certificati ok

      // secondary: "#fdd300",
      // giallo - sono presenti X certificati in scadenza entro i prossimi 60 giorni

      // error: "#FF5252", // rosso
      // condiz. 1: sono presenti X certificati scaduti
      // condiz. 2: sono presenti X certificati scaduti e Y certificati in scadenza entro i prossimi 60 giorni

      let color = "primary";
      if (this.infoCard.hasCertificates) {
        if (this.infoCard.expiredCertificates > 0) {
          color = "error";
        } else if (this.infoCard.expiringCertificates > 0) {
          color = "secondary";
        } else {
          color = "success";
        }
      }

      return color;
    },
    calcCertStatus() {
      let status = "Nessun certificato presente";
      if (this.infoCard.hasCertificates) {
        if (this.infoCard.expiredCertificates > 0) {
          if (this.infoCard.expiredCertificates === 1) {
            status = "E' presente 1 certificato scaduto";
          } else {
            status =
              "Sono presenti " +
              this.infoCard.expiredCertificates +
              " certificati scaduti";
          }

          if (this.infoCard.expiringCertificates > 0) {
            if (this.infoCard.expiringCertificates === 1) {
              status +=
                " e 1 certificato in scadenza entro i prossimi 60 giorni";
            } else {
              status +=
                " e " +
                this.infoCard.expiringCertificates +
                " certificati in scadenza entro i prossimi 60 giorni";
            }
          }
        } else if (this.infoCard.expiringCertificates > 0) {
          if (this.infoCard.expiringCertificates === 1) {
            status =
              "E' presente 1 certificato in scadenza entro i prossimi 60 giorni";
          } else {
            status =
              "Sono presenti " +
              this.infoCard.expiringCertificates +
              " certificati in scadenza entro i prossimi 60 giorni";
          }
        } else {
          status = "Stato certificati valido";
        }
      }

      return status;
    },
    classStyle: function () {
      let cssClass;
      if (this.infoCard.type === "business_service") {
        if (
          this.infoCard.environment == "PROD" &&
          this.infoCard.checkCompliance != "FUORI PERIMETRO" &&
          this.infoCard.perimetroScheda != "NO"
        ) {
          cssClass = "blueProd";
        } else {
          cssClass = "blueNoProd";
        }
      }
      if (this.infoCard.type === "infrastructure_service") {
        if (
          this.infoCard.environment == "PROD" &&
          this.infoCard.checkCompliance != "FUORI PERIMETRO" &&
          this.infoCard.perimetroScheda != "NO"
        ) {
          cssClass = "greenProd";
        } else {
          cssClass = "greenNoProd";
        }
      }
      if (this.infoCard.type === "cluster_software") {
        if (
          this.infoCard.environment == "PROD" &&
          this.infoCard.checkCompliance != "FUORI PERIMETRO" &&
          this.infoCard.perimetroScheda != "NO"
        ) {
          cssClass = "redProd";
        } else {
          cssClass = "redNoProd";
        }
      }
      if (this.infoCard.type === "business_application") {
        if (
          this.infoCard.environment == "PROD" &&
          this.infoCard.checkCompliance != "FUORI PERIMETRO" &&
          this.infoCard.perimetroScheda != "NO"
        ) {
          cssClass = "purpleProd";
        } else {
          cssClass = "purpleNoProd";
        }
      }
      return cssClass;
    },
    serviceCame() {
      var splittedString = this.namecard.split("_");
      let size = splittedString.length;
      return splittedString[size - 1];
    },
  },
  methods: {
    openDialogInfo(value, id) {
      this.$emit("open-dialog-info", value, id);
    },
    goToDetail() {
      localStorage.setItem("infoCard", JSON.stringify(this.infoCard));
      this.$router.push({
        name: "OperatingCardDetails",
        params: {
          id: this.infoCard.id,
          edit: false,
        },
      });
    },
    goToDetailNewTab() {
      localStorage.setItem("infoCard", JSON.stringify(this.infoCard));
      let routeData = this.$router.resolve({
        name: "OperatingCardDetails",
        params: {
          id: this.infoCard.id,
          edit: false,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goToCerts() {
      localStorage.setItem("infoCard", JSON.stringify(this.infoCard));
      this.certsDialogOpen = true;
    },
    goToEdit() {
      localStorage.setItem("infoCard", JSON.stringify(this.infoCard));
      let routeData = this.$router.resolve({
        name: "OperatingCardDetails",
        params: {
          id: this.infoCard.id,
          edit: true,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goTAD() {
      localStorage.setItem("infoCard", JSON.stringify(this.infoCard));
      let routeData = this.$router.resolve({
        name: "OperatingCardDetailsRS",
        params: {
          id: this.infoCard.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    returnSLRLink(came) {
      var url =
        "https://servicemanager.eni.com/sm9/index.do?ctx=docEngine&file=ENISlrAnag&query=came.code%3D%22" +
        came +
        "%22&action=&title=ENISlrAnag%20" +
        came;
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
#referente {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-application .green {
  background-color: #007c80 !important;
}
.underline {
  text-decoration: underline;
}
.complianceYellow {
  color: #fdd300 !important;
}
.complianceGreen {
  color: #4caf50 !important;
}
.complianceBlu {
  color: #3f51b5 !important;
}
.complianceRed {
  color: #f44336 !important;
}
.blueNoProd {
  background: rgb(33, 150, 243);
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 10%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 90%
  );
}
.blueProd {
  background: rgb(33, 150, 243);
}
.greenNoProd {
  background: rgb(0, 124, 128);
  background: linear-gradient(
    90deg,
    rgba(0, 124, 128, 1) 10%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 90%
  );
}
.greenProd {
  background: #007c80;
}
.redNoProd {
  background: rgb(244, 67, 54);
  background: linear-gradient(
    90deg,
    rgb(244, 67, 54) 10%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 90%
  );
}
.redProd {
  background: #f44336;
}
.purpleNoProd {
  background: rgb(63, 81, 181);
  background: linear-gradient(
    90deg,
    rgb(63, 81, 181) 10%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 90%
  );
}
.purpleProd {
  background: #3f51b5;
}
.imgicon {
  width: 20px;
}
.nomargin {
  margin: 0px !important;
}
.fontSize {
  font-size: 12px;
}
</style>
